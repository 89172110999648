<template>
	<div class="admin-adhibition-list el-content">
		<a-table :pagination="false" row-key="order_id" :data-source="info.list" :columns="[
			{title:'ID',dataIndex:'order_id'},
			{title:'订单编号',dataIndex:'order_sn'},
			{title:'订单金额',dataIndex:'amount_actual',slots:{customRender:'amount_actual'}},
			{title:'订单状态',dataIndex:'status',slots:{customRender:'status'}},
			{title:'支付方式',dataIndex:'pay_type',slots:{customRender:'pay_type'}},
			{title:'支付单号',dataIndex:'trans_sn'},
			{title:'支付时间',dataIndex:'pay_time'},
			{title:'订单创建时间',dataIndex:'create_time'},
		]" >
			<template #amount_actual="{record}">
				<span>￥{{record.amount_actual/100}}</span>
			</template>
			<template #status="{record}">
				<a-tag :color="record.status==1 ? '#FF0066' :'#999'">{{record.status==1?'已支付':'未支付'}}</a-tag>
			</template>
			<template #pay_type="{record}">
				<a-tag :color="record.pay_type=='wx' ? '#00CC66' :'#0099FF'">{{record.pay_type=='wx'?'微信支付':'支付宝支付'}}</a-tag>
			</template>
		</a-table>
		<div class="pager">
			<a-pagination
				show-size-changer
				:default-current="info.page"
				:total="info.count"
				@showSizeChange="(p,e)=>{getPluginOrderList(info.page,e)}"
				@change="(e)=>{getPluginOrderList(e,info.limit)}"
			/>
		</div>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import programModel from '@/api/saas/app'
export default{
	setup(){
		const state = reactive({
			info:{
				list:[],
				page:1,
				count:0,
				limit:10
			}
		})
		getPluginOrderList(1,state.info.limit)
		function getPluginOrderList(page,limit){
			let program_id = localStorage.getItem("program_id")
			programModel.getPluginOrder(page,limit,{program_id},res=>state.info = {limit,...res})
		}

		return{
			...toRefs(state),
			getPluginOrderList
		}
	}
}
</script>

<style lang="scss">


</style>
